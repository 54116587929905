import { createAction } from '@reduxjs/toolkit';
import apiWrapper from '../components/helpers/apiWrapper';
import { showErrorMessage, showSuccessMessage } from './messages';
import downloadCsv from '../components/helpers/downloadCsv';

const loadSettingsAction = createAction('LOAD_APP_SETTINGS');
const addAccountManagerAction = createAction('ADD_ACCOUNT_MANAGER');
const updateAccountManagerAction = createAction('UPDATE_ACCOUNT_MANAGER');
const settingChangeAction = createAction('SETTING_CHANGE');
const showLoaderImportProducts = createAction('SHOW_LOADER_IMPORT_PRODUCTS');
const hideLoaderImportProducts = createAction('HIDE_LOADER_IMPORT_PRODUCTS');
const showLoaderImportComponents = createAction(
  'SHOW_LOADER_IMPORT_COMPONENTS'
);
const hideLoaderImportComponents = createAction(
  'HIDE_LOADER_IMPORT_COMPONENTS'
);
const showLoaderExportProducts = createAction('SHOW_LOADER_EXPORT_PRODUCTS');
const hideLoaderExportProducts = createAction('HIDE_LOADER_EXPORT_PRODUCTS');
const showLoaderExportComponents = createAction(
  'SHOW_LOADER_EXPORT_COMPONENTS'
);
const hideLoaderExportComponents = createAction(
  'HIDE_LOADER_EXPORT_COMPONENTS'
);
const attachImportLog = createAction('ATTACH_IMPORT_LOG');

const loadSettings = () => async dispatch => {
  try {
    const result = await apiWrapper.callApi('/api/settings/load');

    return dispatch(loadSettingsAction(result));
  } catch (e) {
    console.log(e);
  }
};

const handleManagersChange = e => dispatch => {
  const { name, value, type, checked } = e.target;

  dispatch(updateAccountManagerAction({ name, value, type, checked }));
};

const handleChange = e => dispatch => {
  const { name, value } = e.target;

  dispatch(settingChangeAction({ name, value }));
};

const save = () => (dispatch, getState) => {
  const { accountManagers, terms, welcome } = getState().appSettings;

  const modifiedManagers = accountManagers
    .filter(manager => manager.modified)
    .map(manager => ({
      id: manager.id,
      email: manager.email,
      is_admin: manager.is_admin,
      products_only: manager.products_only,
      import_allowed: manager.import_allowed,
    }));

  apiWrapper
    .callApiPost('/api/settings/save', {
      accountManagers: modifiedManagers,
      terms,
      welcome,
    })
    .then(res => {
      if (res.error) {
        throw new Error(res.error);
      }

      dispatch(loadSettings());

      dispatch(showSuccessMessage(res.success));
    })
    .catch(e => {
      dispatch(showErrorMessage(`Settings not saved: ${e.message}`));
    });
};

const importProducts = csvFile => async dispatch => {
  await dispatch(showLoaderImportProducts());
  try {
    const data = new FormData();
    data.append('csv', csvFile, csvFile.name);
    const { logs, error } = await apiWrapper.callApiPost(
      '/api/products/importProducts',
      data,
      false,
      300000
    );
    console.log('logs: ', logs);
    if (error) throw new Error(error);
    if (logs) {
      dispatch(
        attachImportLog({ type: 'products', log: logs, date: new Date() })
      );
      dispatch(showSuccessMessage('successfully importet products!'));
    }
  } catch (err) {
    console.log('err: ', err);
    dispatch(showErrorMessage('something went wrong!'));
  } finally {
    await dispatch(hideLoaderImportProducts());
  }
};

const importComponents = csvFile => async dispatch => {
  await dispatch(showLoaderImportComponents());
  try {
    const data = new FormData();
    data.append('csv', csvFile, csvFile.name);
    const { logs, error } = await apiWrapper.callApiPost(
      '/api/products/importComponents',
      data,
      false,
      300000
    );
    console.log('logs: ', logs);
    if (error) throw new Error(error);
    if (logs) {
      dispatch(
        attachImportLog({ type: 'components', log: logs, date: new Date() })
      );
      dispatch(showSuccessMessage('successfully importet components!'));
    }
  } catch (err) {
    console.log('err: ', err);
    dispatch(showErrorMessage('something went wrong!'));
  } finally {
    await dispatch(hideLoaderImportComponents());
  }
};

const exportProducts = () => async dispatch => {
  await dispatch(showLoaderExportProducts());
  try {
    const { csv } = await apiWrapper.callApi('/api/products/exportProducts', 300000);
    downloadCsv(csv, 'products');
  } catch (err) {
    console.log('err: ', err);
    alert('Something went wrong, sorry!');
  } finally {
    await dispatch(hideLoaderExportProducts());
  }
};

const exportComponents = () => async dispatch => {
  await dispatch(showLoaderExportComponents());
  try {
    const { csv } = await apiWrapper.callApi('/api/products/exportComponents', 300000);
    downloadCsv(csv, 'components');
  } catch (err) {
    console.log('err: ', err);
    alert('Something went wrong, sorry!');
  } finally {
    await dispatch(hideLoaderExportComponents());
  }
};

export {
  loadSettingsAction,
  loadSettings,
  addAccountManagerAction,
  updateAccountManagerAction,
  handleManagersChange,
  settingChangeAction,
  handleChange,
  save,
  showLoaderImportProducts,
  hideLoaderImportProducts,
  showLoaderImportComponents,
  hideLoaderImportComponents,
  showLoaderExportProducts,
  hideLoaderExportProducts,
  showLoaderExportComponents,
  hideLoaderExportComponents,
  importComponents,
  importProducts,
  exportComponents,
  exportProducts,
  attachImportLog,
};

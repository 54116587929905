import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Login from './Login';
import Redirect from './Redirect';

const PrivateRoute = ({
  component: Component,
  loggedIn,
  isAdmin,
  adminOnly,
  productsOnly,
  importAllowed,
  allowedForProductsOnly,
  isImportPage,
  path,
  ...rest
}) => {
  if (productsOnly && !allowedForProductsOnly) {
    return (
      <Route
        path={path}
        render={props => <Redirect {...props} redirectPath="/products" />}
      />
    );
  }
  if (!importAllowed && isImportPage) {
    return (
      <Route
        path={path}
        render={props => <Redirect {...props} redirectPath="/" />}
      />
    );
  }
  if (adminOnly && !isAdmin) {
    return <Route path={path} render={<Redirect redirectPath="/" />} />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        if (loggedIn) {
          return (
            <Component
              {...props}
              isAdmin={isAdmin}
              productsOnly={productsOnly}
              importAllowed={importAllowed}
            />
          );
        }

        return <Login />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  loggedIn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  adminOnly: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  loggedIn: false,
  isAdmin: false,
  adminOnly: false,
};

export default PrivateRoute;

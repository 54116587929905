import React from 'react';
import { ImportModal } from './ImportModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import FixedHeader from './FixedHeader';
import {
  loadSettings,
  addAccountManagerAction,
  handleManagersChange,
  handleChange,
  save,
  importProducts,
  importComponents,
  exportProducts,
  exportComponents,
} from '../actions/appSettings';
import { downloadTextFromList } from './helpers/downloadTxt';
import formatDate from './helpers/formatDate';

const mapDispatchToProps = {
  loadSettings,
  addAccountManagerAction,
  handleManagersChange,
  handleChange,
  save,
  importProducts,
  importComponents,
  exportProducts,
  exportComponents,
};

const mapStateToProps = ({
  appSettings,
  loadingExportProducts,
  loadingExportComponents,
  loadingImportProducts,
  loadingImportComponents,
  importLogs,
}) => ({
  appSettings,
  loadingExportProducts,
  loadingExportComponents,
  loadingImportProducts,
  loadingImportComponents,
  importLogs,
});

export class Import extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadSettings();
  }

  openModal = type => {
    this.setState({ modal: type });
  };

  closeModal = () => {
    this.setState({ modal: '' });
  };

  render() {
    const { isAdmin, productsOnly, importAllowed } = this.props;
    const {
      loadingExportProducts,
      loadingImportProducts,
      loadingExportComponents,
      loadingImportComponents,
      importLogs,
    } = this.props.appSettings;
    const { modal } = this.state;

    const DownloadLogLine = ({ type, log, date }) => (
      <div>
        <span
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() =>
            downloadTextFromList(
              log || [],
              `${type}-import-${formatDate(date, 'YYYY-MM-DD')}`
            )
          }
        >
          Click here
        </span>{' '}
        to download {type}-import log{' '}
        {date && <span>from {formatDate(date)}</span>}
      </div>
    );

    const ButtonWithLoader = ({ text, onClick, loading, className }) => (
      <button
        className={'btn btn-primary ' + className}
        onClick={onClick}
        loading={loading}
        style={{ width: 200, height: 40 }}
      >
        {loading ? (
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div>{text}</div>
        )}
      </button>
    );

    return (
      <>
        <FixedHeader
          context="import"
          isAdmin={isAdmin}
          importAllowed={importAllowed}
          productsOnly={productsOnly}
        />
        <div className="container py-3">
          <Helmet defer={false} title="Settings" />
          <div className="row">
            <div className="col col-md-12">
              <div className="form-group">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <h3>Import & Export</h3>
                  </div>
                  <div
                    className="row mt-2"
                    style={{ paddingLeft: 35, paddingRight: 35 }}
                  >
                    <p>Note - when importing:</p>
                    <p>
                      <b>Products</b> - The CSV must contain the correct sell
                      price (i.e. worked out from the currency, supplier RRP,
                      discount etc. as the importer does not carry out the
                      calculations)
                    </p>
                    <p>
                      <b>Components</b> - Once the product ID is mentioned, all
                      existing components (if any) will be deleted and the only
                      components used will be the ones located in the CSV. Like
                      for products, the CSV should work out the correct
                      component cost price (i.e. currency, supplier RRP,
                      discount etc.). The importer will then work out the
                      Supplier Product Cost from all components cost prices. It
                      will also work out the product's Sell Price (using the
                      import costs, markup etc.).
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ margin: '10px 20px' }}>
                      <ButtonWithLoader
                        onClick={this.props.exportProducts}
                        loading={loadingExportProducts}
                        text="Export Products"
                      />
                      <ButtonWithLoader
                        className="ml-4"
                        onClick={() => this.openModal('importProducts')}
                        loading={loadingImportProducts}
                        text="Import Products"
                      />
                    </div>
                    <div className="col-md-12" style={{ margin: '10px 20px' }}>
                      <ButtonWithLoader
                        onClick={this.props.exportComponents}
                        loading={loadingExportComponents}
                        text="Export Components"
                      />
                      <ButtonWithLoader
                        className="ml-4"
                        onClick={() => this.openModal('importComponents')}
                        loading={loadingImportComponents}
                        text="Import Components"
                      />
                    </div>
                    {(importLogs || []).map(logObj => (
                      <div
                        className="col-md-12"
                        style={{ margin: '10px 20px 0px 20px' }}
                      >
                        <DownloadLogLine
                          type={logObj.type}
                          log={logObj.log}
                          date={logObj.date}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImportModal
          type={modal}
          onClose={this.closeModal}
          importProducts={this.props.importProducts}
          importComponents={this.props.importComponents}
        />
      </>
    );
  }
}

Import.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import FixedHeader from '../../FixedHeader';
import QuoteListHeader from './QuoteListHeader';
import QuoteListItem from './QuoteListItem';
import QuotePagination from '../../QuotePagination';
import apiWrapper from '../../helpers/apiWrapper';

class QuoteList extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      quotes: [],
      quotesPerPage: process.env.REACT_APP_QUOTES_PER_PAGE,
      showDuplicateModal: false,
      duplicateQuoteCallback: null,
      duplicateQuoteId: -1,
    };
  }

  componentDidMount() {
    this.loadUsersForFilters();
    this.loadQuotesForCurrentPage(this.getFilters());
  }

  componentDidUpdate(prevProps) {
    const { page: newPage } = this.props.match.params;
    const { page } = prevProps.match.params;

    if (newPage !== page) {
      // remove query string params and leave only page number
      this.loadQuotesForCurrentPage(this.getFilters());
    }
  }

  getCurrentPage = () => {
    const { page } = this.props.match.params;

    return page || 1;
  };

  loadUsersForFilters = async () => {
    const url = `/api/user/all`;

    try {
      const res = await apiWrapper.callApi(`${url}`);

      if (res.error) {
        throw new Error(res.error);
      }

      this.setState({
        users: res,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadQuotesForCurrentPage = filters => {
    const { currentSearch } = this.state;

    if (currentSearch) {
      this.searchQuotes(currentSearch, filters);
    } else {
      this.loadQuotes(this.getCurrentPage(), filters);
    }
  };

  deleteFromList = id => {
    this.setState(prevState => {
      const quotes = prevState.quotes.filter(quote => quote.id !== id);

      return { quotes };
    });
  };

  getFilters = () => {
    const parsed = queryString.parse(this.props.location.search);

    return parsed;
  };

  setCurrentSearch = term => {
    this.setState({
      currentSearch: term,
    });
  };

  searchQuotes = async (term, filters) => {
    try {
      const { results } = await apiWrapper.callApiPost('/api/search', {
        term,
        table: 'quotes',
        columns: ['quote_name', 'customer_name'],
        order: ['date', 'DESC'],
        where: {
          status: filters.status,
          currency: filters.currency,
          user: filters.user,
        },
      });

      this.setState({
        quotes: results.rows || [],
        total: results.count,
        loaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadQuotes = async (page, filters) => {
    const url = `/api/quotes/${page}`;
    const queries = {};
    const parsed = queryString.parse(url);

    // status can be passed in from pagination or when we change the filter
    if (!parsed.status && filters && filters.status) {
      queries.status = filters.status;
    }

    if (!parsed.currency && filters && filters.currency) {
      queries.currency = filters.currency;
    }

    if (!parsed.user && filters && filters.user) {
      queries.user = filters.user;
    }

    try {
      const res = await apiWrapper.callApi(
        `${url}?${queryString.stringify(queries)}`
      );

      if (res.error) {
        throw new Error(res.error);
      }

      this.setState({
        quotes: res.quotes || [],
        total: res.total,
        loaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  updateResults = ({ results, perPage }) => {
    this.setState({
      quotes: results.rows,
      total: results.count,
      quotesPerPage: perPage,
    });
  };

  updateFilter = (selectedKey, selectedOption) => {
    const currentQuery = this.getFilters();
    const search = selectedOption.value
      ? queryString.stringify({
          ...currentQuery,
          [selectedKey]: selectedOption.value,
        })
      : null;

    this.props.history.push({
      pathname: '/quotes',
      search,
    });

    this.loadQuotesForCurrentPage(queryString.parse(search));
  };

  handleDuplicate = (id, callback) => {
    this.setState({
      showDuplicateModal: true,
      duplicateQuoteId: id,
      duplicateQuoteCallback: callback,
    });
  };

  closeDuplicateModal = () => {
    this.setState({
      showDuplicateModal: false,
      duplicateQuoteId: -1,
      duplicateQuoteCallback: null,
    });
  };

  handleDuplicateFinishes = duplicateFinishes => {
    this.state.duplicateQuoteCallback(
      this.state.duplicateQuoteId,
      duplicateFinishes
    );

    this.closeDuplicateModal();
  };

  render() {
    const { quotes, total, loaded, quotesPerPage } = this.state;
    const {
      isAdmin,
      productsOnly,
      importAllowed,
      match: { path },
      location: { search },
    } = this.props;
    const page = this.getCurrentPage();

    return (
      <div>
        <Modal
          isOpen={this.state.showDuplicateModal}
          toggle={this.closeDuplicateModal}
        >
          <ModalHeader toggle={this.closeDuplicateModal}>
            Duplicate Quote
          </ModalHeader>
          <ModalBody>Do you want to also duplicate the finishes?</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleDuplicateFinishes(true);
              }}
            >
              Yes
            </button>
            <button
              className="btn"
              onClick={() => {
                this.handleDuplicateFinishes(false);
              }}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
        <FixedHeader
          isAdmin={isAdmin}
          importAllowed={importAllowed}
          productsOnly={productsOnly}
        />
        <div className="container">
          <QuoteListHeader
            quotes={quotes}
            updateResults={this.updateResults}
            updateFilter={this.updateFilter}
            getFilters={this.getFilters}
            users={this.state.users}
            setCurrentSearch={this.setCurrentSearch}
          />
          <ListGroup>
            {quotes.map(quote => (
              <ListGroupItem key={quote.id} tag="div">
                <QuoteListItem
                  loadQuotes={this.loadQuotes}
                  page={page}
                  quote={quote}
                  createSO={this.createSO}
                  deleteFromList={this.deleteFromList}
                  isAdmin={isAdmin}
                  handleDuplicate={this.handleDuplicate}
                />
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
        {loaded && (
          <QuotePagination
            perPage={+quotesPerPage}
            total={total || 0}
            path={path}
            search={search}
            currentPage={+page}
          />
        )}
      </div>
    );
  }
}

QuoteList.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default QuoteList;

import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { withAlert } from 'react-alert';
import ReactRouterPropTypes from 'react-router-prop-types';
import FixedHeader from '../../FixedHeader';
import ProductListHeader from './ProductListHeader';
import ProductListItem from './ProductListItem';
import Pagination from '../../Pagination';
import apiWrapper from '../../helpers/apiWrapper';
import { getFilteredProducts } from '../../helpers/getFilteredProducts';
import { emptyInitialFilterState } from '../../hooks/useProductFilters';

// TODO: migrate this and QuoteList to a HOC that will get items for selected api endpoint
// TODO: it will get items on load and use current page as key to reset state when the page changes
export class ProductsList extends React.Component {
  constructor(props, context) {
    super(props, context);

    const selectedProducts = ProductsList.getSelectedProducts();

    this.state = {
      selectedProducts: selectedProducts || [],
      products: [],
      productsPerPage: +process.env.REACT_APP_PRODUCTS_PER_PAGE,
      page: 1,
    };
  }

  componentDidMount() {
    const { cleanFilters = true } = this.props?.location?.state || {};

    const pageString = localStorage.getItem('productListPage');
    const page = cleanFilters ? 1 : pageString ? parseInt(pageString) || 1 : 1;
    this.setState({ page });

    this.getTaxonomies();
    this.getProducts(page, cleanFilters);

    // react won't unmount properly when we navigate away, so save data on this event
    window.addEventListener('beforeunload', this.saveSelectedProducts);
  }

  // componentDidUpdate(prevProps) {
  //   const { page: newPage } = this.props.match.params;
  //   const { page } = prevProps.match.params;

  //   if (newPage !== page) {
  //     this.getProducts(newPage);
  //   }
  // }

  componentWillUnmount() {
    this.saveSelectedProducts();
    window.removeEventListener('beforeunload', this.saveSelectedProducts);
    window.removeEventListener('scroll', this.updateOldScroll);
  }

  static getSelectedProducts() {
    return JSON.parse(sessionStorage.getItem('selectedProducts'));
  }

  static setSelectedProducts(selectedProducts) {
    // keep only unique values
    sessionStorage.setItem(
      'selectedProducts',
      JSON.stringify([...new Set(selectedProducts)])
    );
  }

  updateOldScroll = () => {
    localStorage.setItem('productListScroll', window.scrollY);
  };

  getTaxonomies = async () => {
    try {
      const result = await apiWrapper.callApi(`/api/taxonomies`);
      const taxonomies = (result?.taxonomies || []).filter(
        taxonomy => taxonomy.name
      );
      const manufacturers = taxonomies.filter(
        taxonomy => taxonomy.type == 'manufacturer'
      );
      const suppliers = taxonomies.filter(
        taxonomy => taxonomy.type == 'supplier'
      );
      const leadTimes = taxonomies.filter(
        taxonomy => taxonomy.type == 'lead_time'
      );
      const tags = taxonomies.filter(taxonomy => taxonomy.type == 'tag');

      this.setState({
        taxonomies: { manufacturers, suppliers, leadTimes, tags },
      });
    } catch (err) {}
  };

  getProducts = async (page, cleanFilters) => {
    const { history } = this.props;
    const currentPage = page || 1;

    let filters = {};
    if (!cleanFilters) {
      try {
        filters = JSON.parse(localStorage.getItem('productListFilters') || {});
      } catch (err) {}
    }
    const oldScroll = localStorage.getItem('productListScroll');

    try {
      await getFilteredProducts(currentPage, filters, this.updateResults, true);
      setTimeout(() => {
        if (oldScroll && !cleanFilters) window.scrollTo(0, oldScroll);
        this.updateOldScroll();
        window.addEventListener('scroll', this.updateOldScroll);
      }, 250);
    } catch (e) {
    } finally {
      setTimeout(() => {
        if (cleanFilters) {
          localStorage.setItem(
            'productListFilters',
            JSON.stringify(emptyInitialFilterState)
          );
          localStorage.setItem('productListPage', 1);
        }
        history.push({
          pathname: '/products',
          state: { ...(this.props?.location?.state || {}), cleanFilters: true },
        }); // set back clean filters to true for the case of refreshing the page
        this.setState({ pulledOldState: true });
      }, 50);
    }
  };

  saveSelectedProducts = () => {
    const { selectedProducts } = this.state;

    ProductsList.setSelectedProducts(selectedProducts);
  };

  handleChangeCheckbox = e => {
    const { value, checked } = e.target;

    this.setState(prevState => {
      let { selectedProducts } = prevState;

      if (checked) {
        selectedProducts.push(value);
      } else {
        selectedProducts = selectedProducts.filter(val => val !== value);
      }

      return {
        selectedProducts,
      };
    });
  };

  updateResults = ({ results, perPage }) => {
    return this.setState({
      products: results && results.rows ? results.rows : [],
      total: results && results.count ? results.count : 0,
      loaded: true,
    });
  };

  deleteProduct = async id => {
    if (
      !window.confirm(
        'Are you sure you want to delete the product from the app?'
      )
    ) {
      return;
    }

    const { alert } = this.props;

    try {
      const deleted = await apiWrapper.callApi(
        `/api/products/edit/${id}`,
        15000,
        'DELETE'
      );

      if (deleted.error) {
        throw new Error(deleted.error);
      }

      this.setState(prevState => ({
        products: prevState.products.filter(
          product => product.product_id !== id
        ),
      }));

      alert.show(deleted.success, { timeout: 3000, type: 'success' });
    } catch (e) {
      alert.show(e.message, { timeout: 3000, type: 'error' });
    }
  };

  duplicateProduct = async id => {
    const { alert } = this.props;

    try {
      const duplicated = await apiWrapper.callApi(
        `/api/products/duplicate/${id}`,
        15000,
        'POST'
      );

      if (duplicated.error) {
        throw new Error(duplicated.error);
      }

      this.setState(prevState => {
        // debugger;
        const products = [].concat(duplicated.data, prevState.products);
        return {
          products: products,
        };
      });

      alert.show(duplicated.success, { timeout: 3000, type: 'success' });
    } catch (e) {
      alert.show(e.message, { timeout: 3000, type: 'error' });
    }
  };

  render() {
    const {
      products,
      taxonomies,
      total,
      loaded,
      selectedProducts,
      productsPerPage,
      filters,
      page,
      oldScroll = 0,
      pulledOldState,
    } = this.state;

    const {
      isAdmin,
      productsOnly,
      importAllowed,
      location: { state: { editQuote = null, groupId = 0 } = {} },
      match: { path },
    } = this.props;

    return (
      <div>
        <FixedHeader
          context="productList"
          quoteId={editQuote}
          isAdmin={isAdmin}
          productsOnly={productsOnly}
          importAllowed={importAllowed}
          editQuote={editQuote}
          groupId={groupId}
        />
        <div
          style={{
            width: '100%',
            display: pulledOldState ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100,
          }}
        >
          <div
            class="spinner-border"
            style={{ width: '6rem', height: '6rem' }}
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          className="container"
          style={{
            display: pulledOldState ? undefined : 'none',
            marginTop: 75,
          }}
        >
          <ProductListHeader
            products={products}
            table="products"
            searchKeys={[
              'product_name',
              'product_code',
              'supplier_title',
              'supplier_sku',
              'sku',
            ]}
            updateResults={results => {
              if (this.state.pulledOldState) this.updateResults(results);
            }}
            updateFilters={filters => {
              this.setState({ filters });
              // only update the localStorage filters if we have already pulled the old ones
              if (this.state.pulledOldState)
                localStorage.setItem(
                  'productListFilters',
                  JSON.stringify(filters || emptyInitialFilterState)
                );
            }}
            page={page}
            resetPage={() => {
              if (this.state.pulledOldState) {
                localStorage.setItem('productListPage', 1);
                this.setState({ page: 1 });
              }
            }}
            cleanFilters={this.props?.location?.state?.cleanFilters}
          />
          <ListGroup>
            {products.map(product => {
              const { product_id } = product;

              return (
                <ListGroupItem key={product_id} tag="div">
                  <input
                    type="checkbox"
                    className="form-check-input add-to-quote-checkbox"
                    name="add-to-quote"
                    value={product_id}
                    checked={
                      selectedProducts.includes(product_id.toString()) ? 1 : 0
                    }
                    onChange={this.handleChangeCheckbox}
                  />
                  <ProductListItem
                    product={product}
                    taxonomies={taxonomies}
                    deleteProduct={this.deleteProduct}
                    duplicateProduct={this.duplicateProduct}
                    editQuote={editQuote}
                    groupId={groupId}
                  />
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
        <div style={{ display: pulledOldState ? undefined : 'none' }}>
          <Pagination
            editQuote={editQuote}
            groupId={groupId}
            perPage={+process.env.REACT_APP_PRODUCTS_PER_PAGE}
            total={total}
            path={path}
            currentPage={page}
            setPage={page => {
              this.setState({ page });
              localStorage.setItem('productListPage', page);
            }}
          />
        </div>
      </div>
    );
  }
}

ProductsList.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func.isRequired,
  }).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default withAlert()(ProductsList);

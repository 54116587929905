/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Prompt } from 'react-router-dom';
import update, { extend } from 'immutability-helper';
import FixedHeader from '../../FixedHeader';
import Header from '../Header';
import Total from '../Total';
import Footer from './Footer';
import GroupsDisplay from '../GroupsDisplay';
import EditProductFormModal from './EditProductFormModal';
import calculateTotals from '../../helpers/calculateTotals';
import defaultState from '../../../defaultState/quote';
import ProductForm from '../../products/single/ProductForm';
import FinishesModal from './FinishesModal';
import { QuoteLoader } from './QuoteLoader';
import NotFound from '../../404';
import { maxFileSizeMB, maxFileSizeBytes } from '../../../../types';
import apiWrapper from '../../helpers/apiWrapper';

import {
  resetState,
  getQuote,
  saveQuote,
  updateCurrencies,
  loadDefaultTcsAction,
  saveOldScroll,
} from '../../../actions/quote';
import {
  getQuoteProducts,
  resetProducts,
  saveEditedProduct,
  uploadQuoteProductImage,
  uploadFinishes,
  addSelectedProducts,
  deleteRemovedProducts,
  saveQuoteProducts,
} from '../../../actions/quoteProducts';
import {
  getGroups,
  resetGroups,
  updateGroup,
  addGroup,
  changeGroupPosition,
  saveGroups,
  deleteRemovedGroups,
} from '../../../actions/groups';

import { showErrorMessage } from '../../../actions/messages';

const mapDispatchToProps = {
  getQuote,
  saveOldScroll,
  resetState,
  resetProducts,
  resetGroups,
  getQuoteProducts,
  getGroups,
  updateGroup,
  addGroup,
  changeGroupPosition,
  saveGroups,
  deleteRemovedGroups,
  saveEditedProduct,
  uploadQuoteProductImage,
  uploadFinishes,
  addSelectedProducts,
  deleteRemovedProducts,
  saveQuoteProducts,
  saveQuote,
  updateCurrencies,
  loadDefaultTcsAction,
  showErrorMessage,
};

function mapStateToProps({
  quote,
  quoteProducts,
  groups,
  managers,
  settings,
  opportunities,
}) {
  return {
    quote,
    managers,
    opportunities,
    settings: settings.settings,
    groups: groups.groups,
    groupsToDelete: groups.removed,
    products: quoteProducts.products,
    productsToDelete: quoteProducts.removed,
    loading: quote.loading,
    quoteLoading: quote.quoteLoading,
    productsLoading: quoteProducts.productsLoading,
    groupsLoading: groups.groupsLoading,
    oldScroll: quote.oldScroll,
  };
}

function getQuoteCurrencyName(settings) {
  if (settings.is_uk.enabled) {
    return 'GBP';
  } else if (settings.is_us.enabled) {
    return 'USD';
  } else {
    return 'EUR';
  }
}

// add autovivification to objects
extend('$auto', (value, object) => {
  return object ? update(object, value) : update({}, value);
});

export class Quote extends React.Component {
  constructor(props) {
    super(props);

    this.quoteRef = React.createRef();

    this.state = defaultState;
  }

  trackScrollPosition(id, value) {
    localStorage.setItem(`editQuote-scroll-${id}`, value);
  }

  componentWillMount() {
    this.props.resetState();
    this.props.resetProducts();
    this.getTaxonomies();
  }

  async componentDidMount() {
    const { id = '' } = this.props.match.params;
    this.constructQuoteState(id);
  }

  componentDidUpdate(prevProps) {
    const { groups } = this.props;
    // need to wait until the groups are loaded to put selected products in the last one
    if (groups !== prevProps.groups) {
      this.addSelectedProducts();
    }
  }

  componentWillUnmount() {
    const { id = '' } = this.props.match.params;

    this.trackScrollPosition(id, window.scrollY);
    // window.addEventListener('scroll', this.trackScrollPosition);
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  getTaxonomies = async () => {
    try {
      const result = await apiWrapper.callApi(`/api/taxonomies`);
      const taxonomies = (result?.taxonomies || []).filter(
        taxonomy => taxonomy.name
      );
      const manufacturers = taxonomies.filter(
        taxonomy => taxonomy.type == 'manufacturer'
      );
      const suppliers = taxonomies.filter(
        taxonomy => taxonomy.type == 'supplier'
      );
      const leadTimes = taxonomies.filter(
        taxonomy => taxonomy.type == 'lead_time'
      );
      const tags = taxonomies.filter(taxonomy => taxonomy.type == 'tag');

      this.setState({
        taxonomies: { manufacturers, suppliers, leadTimes, tags },
      });

      setTimeout(() => {
        this.setState({
          taxonomiesLoaded: true,
        });
      }, 1000);
    } catch (err) {}
  };

  changeEditedProduct = e => {
    const { name, value } = e.target;

    this.setState(prevState => {
      const form = Object.assign({}, prevState.editProduct);

      form[name] = value;

      return {
        editProduct: form,
      };
    });
  };

  changeSAProductCode = selectedCat => {
    const { value: code } = selectedCat;

    this.setState(prevState => {
      const editProduct = Object.assign({}, prevState.editProduct);
      editProduct.product_code = code;

      return { editProduct };
    });
  };

  // validates fields on modal product form on blur
  validateFields = e => {
    const { name } = e.target;
    const { editProduct } = this.state;
    let invalid = editProduct.invalid || [];

    if (!e.target.checkValidity()) {
      if (!invalid.includes(name)) {
        invalid.push(name);
      }
    } else {
      invalid = invalid.filter(item => item !== name);
    }

    this.setState(prevState => {
      return { editProduct: { ...prevState.editProduct, invalid } };
    });
  };

  setProductImage = file => {
    this.setState(prevState => {
      const { editProduct } = prevState;

      try {
        const image =
          typeof editProduct.image === 'string'
            ? JSON.parse(editProduct.image)
            : editProduct.image;

        if (image && image.length)
          editProduct.image = JSON.stringify([...image, file]);
        else if (image) editProduct.image = JSON.stringify([image, file]);
        else editProduct.image = JSON.stringify([file]);
      } catch (err) {}

      return { editProduct };
    });
  };

  removeProductImage = index => {
    this.setState(prevState => {
      const { editProduct } = prevState;

      if (editProduct.first_img === index) editProduct.first_img = null;
      if (editProduct.second_img === index) editProduct.second_img = null;
      if (editProduct.third_img === index) editProduct.third_img = null;

      try {
        const image =
          typeof editProduct.image === 'string'
            ? JSON.parse(editProduct.image)
            : editProduct.image;

        if (image && image.length > 1) {
          const newImage = [...image];
          newImage.splice(index, 1);
          editProduct.image = JSON.stringify(newImage);
          for (let i = index + 1; i < image.length; i++) {
            if (editProduct.first_img === i) editProduct.first_img = i - 1;
            if (editProduct.second_img === i) editProduct.second_img = i - 1;
            if (editProduct.third_img === i) editProduct.third_img = i - 1;
          }
        } else if (image) editProduct.image = null;
        else alert('No Image to remove!');
      } catch (err) {}

      return { editProduct };
    });
  };

  selectProductImage = (index, position) => {
    this.setState(prevState => {
      const { editProduct } = prevState;

      const oldPosition = (() => {
        if (editProduct.first_img === index) return 1;
        if (editProduct.second_img === index) return 2;
        if (editProduct.third_img === index) return 3;
      })();

      if (position === 1) {
        const oldIndex = editProduct.first_img;
        if (oldPosition === 2) editProduct.second_img = oldIndex;
        if (oldPosition === 3) editProduct.third_img = oldIndex;
        editProduct.first_img = index;
      }

      if (position === 2) {
        const oldIndex = editProduct.second_img;
        if (oldPosition === 1) editProduct.first_img = oldIndex;
        if (oldPosition === 3) editProduct.third_img = oldIndex;
        editProduct.second_img = index;
      }

      if (position === 3) {
        const oldIndex = editProduct.third_img;
        if (oldPosition === 1) editProduct.first_img = oldIndex;
        if (oldPosition === 2) editProduct.second_img = oldIndex;
        editProduct.third_img = index;
      }

      return { editProduct };
    });
  };

  unselectProductImage = index => {
    this.setState(prevState => {
      const { editProduct } = prevState;

      if (editProduct.first_img === index) editProduct.first_img = null;
      if (editProduct.second_img === index) editProduct.second_img = null;
      if (editProduct.third_img === index) editProduct.third_img = null;

      return { editProduct };
    });
  };

  setFinishImage = (file, id) => {
    this.setState(prevState =>
      update(prevState, {
        editProduct: {
          finishes: {
            [id]: {
              $auto: {
                $merge: {
                  finish_number: id,
                  upload_file: file,
                },
              },
            },
          },
        },
      })
    );
  };

  // returns object containing saved quote id
  saveQuote = async () => {
    const {
      groups,
      products,
      groupsToDelete,
      productsToDelete,
      quote,
      managers,
      settings,
      opportunities,
    } = this.props;
    const { id: quoteId = '' } = this.props.match.params;

    const quoteState = {
      ...quote,
      products,
      groups,
      settings: Object.values(settings),
      userId: managers?.selected?.value,
      opportunityId: opportunities?.selected?.value,
      opportunityLabel: opportunities?.selected?.label,
      opportunityType: opportunities?.selected?.type,
    };

    return this.props.saveQuote({
      quoteId,
      quoteState,
      groups,
      products,
      groupsToDelete,
      productsToDelete,
    });
  };

  saveAndQuotes = async () => {
    try {
      const { history } = this.props;

      const saved = await this.saveQuote();

      if (saved) {
        history.push({
          pathname: '/quotes',
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  saveAndEdit = async () => {
    try {
      const oldScroll = window.scrollY;
      this.props.saveOldScroll(oldScroll);
      const savedQuote = await this.saveQuote();

      if (!savedQuote) {
        return;
      }

      const { history } = this.props;
      const { id } = savedQuote;
      this.trackScrollPosition(id, oldScroll);

      this.props.resetState();
      setTimeout(() => {
        this.props.getQuoteProducts(id);

        history.push({
          pathname: `/quotes/edit/${id}`,
          state: { noChangeCheck: true },
        });

        this.constructQuoteState(id);
      }, 250);
    } catch (e) {
      console.log(e);
    }
  };

  updateCurrencies = async () => {
    const {
      groups,
      products,
      groupsToDelete,
      productsToDelete,
      quote,
      managers,
      settings,
      opportunities,
    } = this.props;
    const { id: quoteId = '' } = this.props.match.params;

    const quoteState = {
      ...quote,
      products,
      groups,
      settings: Object.values(settings),
      userId: managers?.selected?.value,
      opportunityId: opportunities?.selected?.value,
      opportunityLabel: opportunities?.selected?.label,
      opportunityType: opportunities?.selected?.type,
    };

    return this.props.updateCurrencies({
      quoteId,
      quoteState,
      groups,
      products,
      groupsToDelete,
      productsToDelete,
    });
  };

  constructQuoteState = id => {
    this.props.resetState();
    this.props.resetProducts();
    this.props.getQuote(id);
    this.props.getQuoteProducts(id);
    this.props.getGroups(id);
  };

  isChanged = () => {
    const { location } = this.props;

    // this is to update without prompt when developing
    if (location.search.includes('noChangeCheck')) {
      return false;
    }

    return this.props.quote.updated;
  };

  // this is to handle browser tab close and reload
  beforeUnload = e => {
    if (this.isChanged()) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  openModal = (product, modalType) => {
    this.setState(prevState =>
      update(prevState, {
        modal: { $set: modalType },
        editProduct: { $set: product },
      })
    );
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  addSelectedProducts = () => {
    // addSelected is received from the pagination component
    const {
      location: { state: { addSelected } = {} },
      groups,
    } = this.props;

    if (!addSelected) {
      return;
    }

    const selectedProductsIdsJSON = sessionStorage.getItem('selectedProducts');

    if (!selectedProductsIdsJSON) {
      return;
    }

    const selectedProductsIds = JSON.parse(selectedProductsIdsJSON);
    const groupId = this.getSelectedProductsGroupId(groups);

    this.props.addSelectedProducts(selectedProductsIds, groupId);
  };

  getSelectedProductsGroupId = groups => {
    const {
      location: { state },
    } = this.props;
    const { groupId } = state;

    if (groupId) {
      return groupId;
    }

    // get the last group id
    return groups && [groups.length - 1] ? groups[groups.length - 1].id : null;
  };

  saveEditedProduct = async modalType => {
    const { editProduct } = this.state;
    const { id } = editProduct;
    const productToSave = { ...editProduct };
    // the fields that are marked as invalid
    const { invalid = [] } = editProduct;
    if (invalid.length) {
      invalid.forEach(field => {
        document.querySelector(`[name=${field}]`).reportValidity();
      });
      return;
    }

    if (
      productToSave.image instanceof File &&
      productToSave.image.size > maxFileSizeBytes
    ) {
      this.props.showErrorMessage(
        `The image is too large, max file size is ${maxFileSizeMB}MB`
      );
      return;
    }

    this.closeModal();

    if (modalType === 'editProduct' && productToSave.image instanceof File) {
      productToSave.image = await this.props.uploadQuoteProductImage(
        id,
        productToSave.image
      );
    } else if (modalType === 'finishes') {
      const quoteId = this.props.quote.id;
      // process finishes
      productToSave.finishes = await this.props.uploadFinishes(
        id,
        quoteId,
        productToSave.finishes
      );
    }
    this.props.saveEditedProduct(productToSave);
    this.setState(prevState =>
      update(prevState, {
        loading: { $set: false },
      })
    );
  };

  // TODO:  move to actions and remove
  updateTCS = tcs => {
    this.setState({ tcs });
  };

  editFinishesTitles = e => {
    const { name, value } = e.target;
    // the last char of the input name contains its order
    const finishNum = +name[name.length - 1];

    this.setState(prevState =>
      update(prevState, {
        editProduct: {
          finishes: {
            [finishNum]: {
              $auto: {
                $merge: {
                  finish_number: finishNum,
                  finish_title: value,
                },
              },
            },
          },
        },
      })
    );
  };

  resetFinishes = () => {
    const { finishes: currentFinishes } = this.state.editProduct;
    const resetFinishes = [];

    for (let i = 0; i < 3; i++) {
      resetFinishes.push({
        finish_swatch_image: '',
        finish_title: '',
        upload_file: null,
        finish_number: i,
        id: (currentFinishes[i] || {}).id || null,
      });
    }

    this.setState(prevState =>
      update(prevState, {
        editProduct: {
          finishes: { $set: resetFinishes },
        },
      })
    );
  };

  showLeaveMessage = nextLocation => {
    const { location } = this.props;
    if (
      location.pathname === nextLocation.pathname ||
      (nextLocation.state || {}).noChangeCheck ||
      !this.isChanged()
    ) {
      return true;
    }

    return 'The quote is not saved. Are you sure you want to leave?';
  };

  onUpdateComponents = result => {
    this.setState({
      editProduct: { ...result, components: result.components },
    });
  };

  render = () => {
    const { notFound, modal, editProduct = {} } = this.state;

    if (notFound) {
      return <Route component={NotFound} status={404} />;
    }

    const { quote, products, settings } = this.props;

    console.log('quote: ', quote);

    const { slug, randslug } = quote;

    const { id = '' } = this.props.match.params;

    console.log(editProduct);

    const {
      isAdmin,
      productsOnly,
      importAllowed,
      history: { goBack },
      loading,
      quoteLoading,
      productsLoading,
      groupsLoading,
      oldScroll,
    } = this.props;

    // TODO: move this out from render, add check if we really need to recalculate
    // TODO: maybe consider using reselect
    const subtotal = calculateTotals(products, getQuoteCurrencyName(settings));
    const showPageLoader =
      loading || quoteLoading || productsLoading || groupsLoading;
    const localStorageScroll =
      localStorage.getItem(`editQuote-scroll-${id}`) || 0;

    return (
      <React.Fragment>
        <Prompt message={this.showLeaveMessage} />
        <FixedHeader
          context="editQuote"
          saveQuote={this.saveAndEdit}
          updateCurrencies={this.updateCurrencies}
          isAdmin={isAdmin}
          importAllowed={importAllowed}
          productsOnly={productsOnly}
        />
        <div style={{ marginTop: 100 }}>
          <QuoteLoader
            loading={showPageLoader}
            oldScroll={oldScroll || localStorageScroll}
          />
        </div>
        <div
          className="container"
          ref={this.quoteRef}
          style={{ display: showPageLoader ? 'none' : undefined }}
        >
          <Header />
          <GroupsDisplay
            openModal={this.openModal}
            context="edit"
            data-test-id="GroupsDisplay"
            taxonomies={this.state.taxonomies}
            taxonomiesLoaded={this.state.taxonomiesLoaded}
          />
          <Total title="Low Cost" option="B" subtotal={subtotal.B} />
          <Total title="High Cost" option="A" subtotal={subtotal.A} />

          <Footer
            id={id}
            subtotal={subtotal}
            slug={slug}
            randslug={randslug}
            saveQuote={this.saveAndEdit}
            saveAndQuotes={this.saveAndQuotes}
            goBack={goBack}
            loading={loading}
          />
          <EditProductFormModal
            isOpen={!!modal}
            closeModal={this.closeModal}
            saveEditedProduct={this.saveEditedProduct}
            modalType={modal}
          >
            {modal === 'finishes' ? (
              <FinishesModal
                changeFinishesImages={this.setFinishImage}
                product={editProduct}
                editFinishesTitles={this.editFinishesTitles}
                resetFinishes={this.resetFinishes}
              />
            ) : (
              editProduct && (
                <ProductForm
                  form={editProduct}
                  handleChange={this.changeEditedProduct}
                  handleSelect={this.changeSAProductCode}
                  handleBlur={this.validateFields}
                  quoteId={id}
                  onUpdateComponents={this.onUpdateComponents}
                  isQuoteProduct={true}
                  addFile={this.setProductImage}
                  removeImage={this.removeProductImage}
                  selectImage={this.selectProductImage}
                  unselectImage={this.unselectProductImage}
                />
              )
            )}
          </EditProductFormModal>
        </div>
      </React.Fragment>
    );
  };
}

Quote.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      addSelected: PropTypes.bool,
      groupId: PropTypes.number,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quote);

/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import appSettingsDefaultState from '../defaultState/appSettings';
import {
  loadSettingsAction,
  addAccountManagerAction,
  updateAccountManagerAction,
  settingChangeAction,
  showLoaderImportProducts,
  hideLoaderImportProducts,
  showLoaderImportComponents,
  hideLoaderImportComponents,
  showLoaderExportProducts,
  hideLoaderExportProducts,
  showLoaderExportComponents,
  hideLoaderExportComponents,
  attachImportLog,
} from '../actions/appSettings';

const appSettings = createReducer(appSettingsDefaultState, {
  [loadSettingsAction]: (state, { payload }) => {
    return payload;
  },
  [addAccountManagerAction]: (state, { payload }) => {
    const { accountManagers } = state;
    let maxId = accountManagers.length + 1;

    Object.values(accountManagers).forEach(item => {
      maxId = item.id >= maxId ? item.id + 1 : maxId;
    });

    accountManagers.push({
      temp_id: maxId,
      email: '',
      is_admin: 0,
      products_only: 0,
      import_allowed: 0,
    });
  },
  [updateAccountManagerAction]: (state, { payload }) => {
    const { accountManagers } = state;
    const { name, type, value, checked } = payload;
    // the first one is full match, don't need it
    const [, field, id] = name.match(/(.*)\[(\d+)\]/) || [];

    if (!id || Number.isNaN(+id)) return;

    const fieldValue = type === 'checkbox' ? checked : value;

    const index = accountManagers.findIndex(
      item => +item.id === +id || +item.temp_id === +id
    );

    accountManagers[index] = {
      ...accountManagers[index],
      [field]: fieldValue,
      modified: true,
    };
  },
  [settingChangeAction]: (state, { payload }) => {
    const { name, value } = payload;

    state[name] = value;
  },
  [showLoaderImportProducts]: state => {
    state.loadingImportProducts = true;
  },
  [hideLoaderImportProducts]: state => {
    state.loadingImportProducts = false;
  },
  [showLoaderImportComponents]: state => {
    state.loadingImportComponents = true;
    console.log('state at show: ', state);
  },
  [hideLoaderImportComponents]: state => {
    state.loadingImportComponents = false;
    console.log('state at hide: ', state);
  },
  [showLoaderExportProducts]: state => {
    state.loadingExportProducts = true;
  },
  [hideLoaderExportProducts]: state => {
    state.loadingExportProducts = false;
  },
  [showLoaderExportComponents]: state => {
    state.loadingExportComponents = true;
  },
  [hideLoaderExportComponents]: state => {
    state.loadingExportComponents = false;
  },
  [attachImportLog]: (state, { payload }) => {
    if (payload) state.importLogs = [...(state.importLogs || []), payload];
  },
});

export default appSettings;

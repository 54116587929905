import React from 'react';
import { ImportModal } from './ImportModal';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FixedHeader from './FixedHeader';
import {
  loadSettings,
  addAccountManagerAction,
  handleManagersChange,
  handleChange,
  save,
  importProducts,
  importComponents,
  exportProducts,
  exportComponents,
} from '../actions/appSettings';
import apiWrapper from './helpers/apiWrapper';
import downloadCsv from './helpers/downloadCsv';
import { CurrenciesForm } from './settings/CurrenciesForm';
import Button from './Button';
import withLoader from './withLoader';
import { downloadTextFromList } from './helpers/downloadTxt';
import formatDate from './helpers/formatDate';

const mapDispatchToProps = {
  loadSettings,
  addAccountManagerAction,
  handleManagersChange,
  handleChange,
  save,
  importProducts,
  importComponents,
  exportProducts,
  exportComponents,
};

const mapStateToProps = ({
  appSettings,
  loadingExportProducts,
  loadingExportComponents,
  loadingImportProducts,
  loadingImportComponents,
  importLogs,
}) => ({
  appSettings,
  loadingExportProducts,
  loadingExportComponents,
  loadingImportProducts,
  loadingImportComponents,
  importLogs,
});

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadSettings();
  }

  openModal = type => {
    this.setState({ modal: type });
  };

  closeModal = () => {
    this.setState({ modal: '' });
  };

  render() {
    const { appSettings } = this.props;
    const { accountManagers, terms, welcome } = appSettings;
    const { isAdmin, productsOnly, importAllowed } = this.props;
    const {
      loadingExportProducts,
      loadingImportProducts,
      loadingExportComponents,
      loadingImportComponents,
      importLogs,
    } = this.props.appSettings;
    const { modal, uploadedFile } = this.state;

    const DownloadLogLine = ({ type, log, date }) => (
      <div>
        <span
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() =>
            downloadTextFromList(
              log || [],
              `${type}-import-${formatDate(date, 'YYYY-MM-DD')}`
            )
          }
        >
          Click here
        </span>{' '}
        to download {type}-import log{' '}
        {date && <span>from {formatDate(date)}</span>}
      </div>
    );

    const ButtonWithLoader = ({ text, onClick, loading, className }) => (
      <button
        className={'btn btn-primary ' + className}
        onClick={onClick}
        loading={loading}
        style={{ width: 200, height: 40 }}
      >
        {loading ? (
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div>{text}</div>
        )}
      </button>
    );

    return (
      <>
        <FixedHeader
          context="settings"
          isAdmin={isAdmin}
          importAllowed={importAllowed}
          productsOnly={productsOnly}
        />
        <div className="container py-3">
          <Helmet defer={false} title="Settings" />
          <div className="row">
            <div className="col col-md-12">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12">
                    <h3>Currencies</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ margin: '10px 20px' }}>
                      <CurrenciesForm />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h3>Account Manager</h3>
                  </div>
                  <div className="col-md-2">
                    <span className="align-middle">Is admin?</span>
                  </div>
                  <div className="col-md-2">
                    <span className="align-middle">Products only?</span>
                  </div>
                  <div className="col-md-2">
                    <span className="align-middle">Import allowed?</span>
                  </div>
                </div>
                {accountManagers.map((manager, i) => {
                  return (
                    <div key={i} className="row mb-3">
                      <span className="col-md-6">
                        <input
                          type="email"
                          name={`email[${manager.id || manager.temp_id || ''}]`}
                          className="form-control"
                          value={manager.email || ''}
                          onChange={this.props.handleManagersChange}
                        />
                      </span>
                      <span className="col-md-2">
                        <input
                          type="checkbox"
                          name={`is_admin[${manager.id ||
                            manager.temp_id ||
                            ''}]`}
                          onChange={this.props.handleManagersChange}
                          checked={manager.is_admin}
                        />
                      </span>
                      <span className="col-md-2">
                        <input
                          type="checkbox"
                          name={`products_only[${manager.id ||
                            manager.temp_id ||
                            ''}]`}
                          onChange={this.props.handleManagersChange}
                          checked={manager.products_only}
                        />
                      </span>
                      <span className="col-md-2">
                        <input
                          type="checkbox"
                          name={`import_allowed[${manager.id ||
                            manager.temp_id ||
                            ''}]`}
                          onChange={this.props.handleManagersChange}
                          checked={manager.import_allowed}
                        />
                      </span>
                    </div>
                  );
                })}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.addAccountManagerAction}
                >
                  Add New
                </button>
              </div>
              <div className="form-group">
                <h3>Terms & Conditions</h3>
                <textarea
                  name="terms"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={terms}
                  onChange={this.props.handleChange}
                />
              </div>
              <div className="form-group">
                <h3>Welcome Screen Text</h3>
                <textarea
                  name="welcome"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={welcome}
                  onChange={this.props.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 text-left">
              <Link to="/" className="btn btn-link">
                Back
              </Link>
            </div>
            <div className="col col-md-6 text-right">
              <button
                type="button"
                className="btn btn-primary save-button"
                onClick={this.props.save}
              >
                Save Settings
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Settings.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

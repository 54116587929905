import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import FixedHeader from '../FixedHeader';
import { useTaxonomies } from './useTaxonomies';
import './Taxonomies.css';
import Select from 'react-select';
import SearchInput from 'react-search-input';
import { ButtonWithLoader } from '../ButtonWithLoader';

const taxonomyTypeOptions = [
  { value: 'supplier', label: 'Suppliers' },
  { value: 'manufacturer', label: 'Manufacturers' },
  { value: 'lead_time', label: 'Lead Times' },
  { value: 'tag', label: 'Tags' },
];

const Taxonomies = ({
  isAdmin,
  productsOnly,
  importAllowed,
  location: { state: { editQuote = null, groupId = 0 } = {} },
}) => {
  const [search, setSearch] = useState('');
  const [taxonomyType, setTaxonomyType] = useState(taxonomyTypeOptions[0]);
  const {
    taxonomies,
    addTaxonomy,
    editTaxonomy,
    submitChanges,
    submitting,
  } = useTaxonomies(taxonomyType.value, search);
  const count = (taxonomies || []).length;

  return (
    <div>
      <FixedHeader
        context="taxonomies"
        quoteId={editQuote}
        isAdmin={isAdmin}
        importAllowed={importAllowed}
        editQuote={editQuote}
        groupId={groupId}
      />
      <div className="container">
        <div>
          <Helmet defer={false}>
            <title>Taxonomies</title>
          </Helmet>
          <div className="row">
            <div className="col-md-12 mx-auto text-center">
              <h1>Taxonomies</h1>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-4 mx-auto text-center">
              <ButtonWithLoader
                text="Submit changes"
                onClick={submitChanges}
                loading={submitting}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-4 ml-auto text-center">
              <SearchInput
                className="search-input"
                value={search}
                onChange={setSearch}
                throttle={300}
              />
            </div>
            <div className="col-md-4 mr-auto text-center">
              <Select
                label="Type"
                value={taxonomyType}
                options={taxonomyTypeOptions}
                onChange={setTaxonomyType}
              />
            </div>
            <div className="col-md-4 mr-auto text-center">
              <button
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={addTaxonomy}
              >
                Add entry
              </button>
            </div>
          </div>
        </div>
        <div className="taxonomies-counter-row">
          {`${count} ${count === 1 ? 'entry' : 'entries'}`}
        </div>
        <div className="taxonomies-container">
          {(taxonomies || []).map(taxonomy => {
            return (
              <div className="taxonomies-row">
                <input
                  type="text"
                  className="taxonomies-input"
                  value={taxonomy.name}
                  onChange={e => editTaxonomy(taxonomy.index, e.target.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Taxonomies.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func.isRequired,
  }).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default Taxonomies;

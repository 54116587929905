import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ReactRouterPropTypes from 'react-router-prop-types';
import ProductForm from './ProductForm';
import ProductFormFooter from './ProductFormFooter';
import FixedHeader from '../../FixedHeader';
import apiWrapper from '../../helpers/apiWrapper';
import NotFound from '../../404';
import { getImages } from '../../helpers/getImages';
import { maxFileSizeMB, maxFileSizeBytes } from '../../../../types';
import Button from '../../Button';
import withLoader from '../../withLoader';

const ButtonWithLoader = withLoader(Button);

library.add(faArrowLeft, faArrowRight);

export class ProductFormContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      form: {
        product_name: '',
        cost_price: '0',
        supplier_rrp: '',
        supplier_discount: '',
        sell_price: '',
        percentage_increase: '0',
        fixed_price_surcharge: '0',
        dimensions: '',
        bullets: '',
        warranty: '',
        swatches_title: '',
        swatches_url: '',
        brochure_title: '',
        brochure_url: '',
        supplier_price_list_url: '',
        min_sell_price: '',
        suggested_direct_markup: '',
        suggested_trade_markup: '',
        lead_time: '',
        sku: '',
        sales_notes: '',
        manufacturer: '',
        supplier: '',
        currency: 'EUR',
        // this is a workaround for testing
        // it throws too many warnings about SACatsSelect updates not being wrapped in act
        // on mounting this component so pass product code to avoid using useEffect on load in SACatsSelect
        product_code: (props.form && props.form.product_code) || '',
      },
      loading: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.setProductData();
  }

  componentDidUpdate(prevProps) {
    // window.scrollTo(0, 0);

    const { id: oldId } = prevProps.match.params;
    const { id } = this.props.match.params;

    if (id !== oldId) {
      this.setProductData();
    }
  }

  setProductData = async () => {
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    try {
      this.setState({ switchingProduct: true });

      const product = await apiWrapper.callApi(`/api/products/edit/${id}`);

      if (product && product.notFound) {
        this.setState({
          notFound: true,
        });

        return;
      }

      const prevNext = await apiWrapper.callApi(
        `/api/products/getPrevNextProductIds/${id}`
      );

      if (typeof product?.components == 'string') {
        product.components = JSON.parse(product.components);
      }

      this.setState({
        form: product,
        prevNext,
        switchingProduct: false,
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  showLoader = () => {
    this.setState({
      loading: true,
    });
  };

  hideLoader = () => {
    this.setState({
      loading: false,
    });
  };

  getEditQuote = () => {
    return (this.props.location.state || {}).editQuote;
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.showLoader();

    const { alert, match } = this.props;
    const { id = '' } = match.params;
    const { form } = this.state;
    const data = new FormData();
    const { editQuote, groupId } = this.props.location.state || {};

    if (form) {
      form.manufacturer_is_supplier = form.manufacturer_is_supplier ? 1 : 0;
      form.manufacturer_unknown = form.manufacturer_unknown ? 1 : 0;
      form.add_supplier_details = form.add_supplier_details ? 1 : 0;
      form.fixed_price_surcharge = form.fixed_price_surcharge || 0;
      form.percentage_increase = form.percentage_increase || 0;
    }

    Object.keys(form).forEach(field => {
      const val = !!form[field] || form[field] == 0 ? form[field] : '';
      if (field === 'components') data.append(field, JSON.stringify(val));
      else data.append(field, val);
    });

    try {
      // handle file uploading
      // if (form.image instanceof Blob || form.image instanceof File) {
      //   if (form.image.size > maxFileSizeBytes) {
      //     throw new Error(
      //       `The image is too large, max file size is ${maxFileSizeMB}MB`
      //     );
      //   }
      //   // the file being uploaded
      //   data.append('image', form.image, form.image.name);
      // } else if (form.image && !data.get('image')) {
      //   // old file stored as string
      //   data.append('image', form.image);
      // }

      const res =
        (await apiWrapper.callApiPost(
          `/api/products/edit/${id}`,
          data,
          false
        )) || {};

      if (res.error) {
        throw new Error(res.error);
      }

      this.hideLoader();

      this.props.history.push({
        pathname: '/products',
        state: { editQuote, groupId, cleanFilters: false },
      });

      alert.show(res.success, {
        timeout: 3000,
        type: 'success',
      });
    } catch (e) {
      this.hideLoader();

      alert.show(e.message, { timeout: 3000, type: 'error' });
      console.log(e);
    }
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState(prevState => {
      const form = Object.assign({}, prevState.form);

      form[name] = value;

      return {
        form,
      };
    });
  };

  handleSelect = selectedCat => {
    const { value: code } = selectedCat;

    this.setState(prevState => {
      const form = Object.assign({}, prevState.form);
      form.product_code = code;

      return {
        form,
      };
    });
  };

  addFile = file => {
    this.setState(prevState => {
      const { form } = prevState;

      try {
        const image =
          typeof form.image === 'string' ? JSON.parse(form.image) : form.image;

        if (image && image.length)
          form.image = JSON.stringify([...image, file]);
        else if (image) form.image = JSON.stringify([image, file]);
        else form.image = JSON.stringify([file]);
      } catch (err) {}

      return { form };
    });
  };

  removeImage = index => {
    this.setState(prevState => {
      const { form } = prevState;

      if (form.first_img === index) form.first_img = null;
      if (form.second_img === index) form.second_img = null;
      if (form.third_img === index) form.third_img = null;

      try {
        const image =
          typeof form.image === 'string' ? JSON.parse(form.image) : form.image;

        if (image && image.length > 1) {
          const newImage = [...image];
          newImage.splice(index, 1);
          form.image = JSON.stringify(newImage);
          for (let i = index + 1; i < image.length; i++) {
            if (form.first_img === i) form.first_img = i - 1;
            if (form.second_img === i) form.second_img = i - 1;
            if (form.third_img === i) form.third_img = i - 1;
          }
        } else if (image) form.image = null;
        else alert('No Image to remove!');
      } catch (err) {}

      return { form };
    });
  };

  selectImage = (index, position) => {
    this.setState(prevState => {
      const { form } = prevState;

      const oldPosition = (() => {
        if (form.first_img === index) return 1;
        if (form.second_img === index) return 2;
        if (form.third_img === index) return 3;
      })();

      if (position === 1) {
        const oldIndex = form.first_img;
        if (oldPosition === 2) form.second_img = oldIndex;
        if (oldPosition === 3) form.third_img = oldIndex;
        form.first_img = index;
      }

      if (position === 2) {
        const oldIndex = form.second_img;
        if (oldPosition === 1) form.first_img = oldIndex;
        if (oldPosition === 3) form.third_img = oldIndex;
        form.second_img = index;
      }

      if (position === 3) {
        const oldIndex = form.third_img;
        if (oldPosition === 1) form.first_img = oldIndex;
        if (oldPosition === 2) form.second_img = oldIndex;
        form.third_img = index;
      }

      return { form };
    });
  };

  unselectImage = index => {
    this.setState(prevState => {
      const { form } = prevState;

      if (form.first_img === index) form.first_img = null;
      if (form.second_img === index) form.second_img = null;
      if (form.third_img === index) form.third_img = null;

      return { form };
    });
  };

  render() {
    const { notFound } = this.state;
    const editQuote = this.getEditQuote();

    if (notFound) {
      return <Route component={NotFound} status={404} />;
    }

    const { form, loading, prevNext, switchingProduct } = this.state;
    const {
      isAdmin,
      productsOnly,
      importAllowed,
      location: { state: { groupId = 0 } = {} },
      match,
    } = this.props;

    const { id } = match.params;
    const title = `Product: ${id ? 'Edit' : 'New'}`;

    return (
      <div className="container pb-4">
        <FixedHeader
          context="product"
          isAdmin={isAdmin}
          productsOnly={productsOnly}
          importAllowed={importAllowed}
        />
        <Helmet defer={false} title={title} />
        <h1 className="my-3">{title}</h1>
        <div className="single-product-nav-row flex justify-between">
          <div>
            {prevNext && prevNext.prev && (
              <Link
                to={{
                  pathname: `/products/edit/${prevNext.prev}`,
                  state: { editQuote, groupId },
                }}
                className={`${
                  switchingProduct ? 'disabled' : ''
                } btn-outline-primary btn single-product-nav prev-product-link`}
              >
                {/* eslint-disable-next-line */}
                <FontAwesomeIcon icon={faArrowLeft} /> Prev
              </Link>
            )}
          </div>
          <div>
            <Link
              className="btn btn-secondary mr-2"
              to={{
                pathname: '/products',
                state: {
                  editQuote,
                  groupId,
                  cleanFilters: false,
                },
              }}
            >
              Back
            </Link>

            <ButtonWithLoader
              type="button"
              className="btn btn-primary submit-product-form"
              text="Save"
              loaded={!loading}
              onClick={this.handleSubmit}
            />
          </div>
          <div>
            {prevNext && prevNext.next && (
              <Link
                to={{
                  pathname: `/products/edit/${prevNext.next}`,
                  state: { editQuote, groupId },
                }}
                style={{ marginLeft: 0 }}
                className={`${
                  switchingProduct ? 'disabled' : ''
                } btn-outline-primary btn single-product-nav next-product-link`}
              >
                {/* eslint-disable-next-line */}
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            )}
          </div>
        </div>
        <ProductForm
          form={form || {}}
          handleSelect={this.handleSelect}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          addFile={this.addFile}
          removeImage={this.removeImage}
          selectImage={this.selectImage}
          unselectImage={this.unselectImage}
        />
      </div>
    );
  }
}

ProductFormContainer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func.isRequired,
  }).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withAlert()(ProductFormContainer);
